import { htmlToText } from 'html-to-text';
import { toast } from 'sonner';

import { LanguageEnum } from '@/schema';

import { reportToSentry } from './sentry';

const language = navigator.language;
const pluralRules = new Intl.PluralRules(language);
const displayNames = new Intl.DisplayNames([language], { type: 'language' });

export const isProdEnv = import.meta.env.MODE === 'production';

export function getLanguageLabel(locale: LanguageEnum): string {
  return displayNames.of(locale) ?? 'Unknown language';
}

export function pluralize(
  count: number,
  singular: string,
  plural: string,
): string {
  const grammaticalNumber = pluralRules.select(count);
  return grammaticalNumber === 'one' ? singular : plural;
}

export const copyElementContent = async (element?: HTMLElement) => {
  if (!element) {
    throw new Error('Element is required');
  }

  const plainText = htmlToText(element.innerHTML);

  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([element.innerHTML], { type: 'text/html' }),
        'text/plain': new Blob([plainText], { type: 'text/plain' }),
      }),
    ]);
  } catch (error) {
    const err = error as Error;

    console.error('Failed to copy text: ', err);

    toast.error('Failed to copy text.', {
      description: err?.message,
    });

    reportToSentry(err);

    throw err;
  }
};
