import { Column, Table } from '@tanstack/react-table';
import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  Columns4Icon,
} from 'lucide-react';

import { cn } from '@/utils/tailwind';

import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  table: Table<TData>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  table,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <Button
        variant="ghost"
        size="sm"
        className="-mx-3 flex h-8 gap-2 whitespace-normal data-[state=open]:bg-accent"
        onClick={() => {
          table.setPageIndex(0);
          column.toggleSorting();
        }}
      >
        <span>{title}</span>

        {column.getIsSorted() === 'desc' ? (
          <ArrowDownIcon className="size-4" />
        ) : column.getIsSorted() === 'asc' ? (
          <ArrowUpIcon className="size-4" />
        ) : (
          <ArrowUpDownIcon className="size-4" />
        )}
      </Button>
    </div>
  );
}

export const VisibilityColumnHeader = <TData,>(props: {
  table: Table<TData>;
}) => {
  const { table } = props;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <Columns4Icon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Visible columns:</DropdownMenuLabel>

        {table
          .getAllColumns()
          .filter((column) => column.getCanHide())
          .map((column) => (
            <DropdownMenuCheckboxItem
              key={column.id}
              className="capitalize"
              checked={column.getIsVisible()}
              onSelect={(ev) => {
                ev.preventDefault();
                column.toggleVisibility();
              }}
            >
              {column.columnDef.meta?.label ?? column.id}
            </DropdownMenuCheckboxItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
