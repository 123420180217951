import {
  OnChangeFn,
  PaginationState,
  type VisibilityState,
} from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { pendingReasonColumn } from '@/components/billing/billing-table-column-def';

import { BillingTab } from './billing-state';

export interface TableState {
  pageSize: number;
  columnVisibility: Record<string, VisibilityState>;
  setPageSize: (pageSize: number) => void;
  setColumnVisibility: (key: string, visibility: VisibilityState) => void;
}

export const useTableState = create<TableState>()(
  devtools(
    persist(
      (set) => ({
        pageSize: 10,
        columnVisibility: {
          [BillingTab.Pending]: {
            [pendingReasonColumn.id!]: false,
          },
        },
        setPageSize: (pageSize) => set({ pageSize }),
        setColumnVisibility: (key, visibility) =>
          set((state) => ({
            columnVisibility: {
              ...state.columnVisibility,
              [key]: {
                ...state.columnVisibility[key],
                ...visibility,
              },
            },
          })),
      }),
      { name: 'table-state' },
    ),
    { name: 'table-state' },
  ),
);

export const usePaginationState = (): {
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
} => {
  const { pageSize, setPageSize } = useTableState();

  const [pagination, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  // The reason to use useCallback here is to avoid render loops derived from the state changing
  const setPagination: OnChangeFn<PaginationState> = useCallback(
    (newPagination) => {
      setPaginationState((v) => {
        const updatedPagination =
          typeof newPagination === 'function'
            ? newPagination(v)
            : newPagination;

        if (updatedPagination.pageSize !== v.pageSize) {
          setPageSize(updatedPagination.pageSize);
        }

        return updatedPagination;
      });
    },
    [setPageSize, setPaginationState],
  );

  useEffect(() => {
    setPaginationState({ pageIndex: 0, pageSize });
  }, [pageSize]);

  return { pagination, setPagination };
};

export const selectColumnVisibility = (key: string) => (state: TableState) =>
  state.columnVisibility[key];

export const selectSetColumnVisibility = (state: TableState) =>
  state.setColumnVisibility;

export const useColumnVisibility = (key: string) => {
  const columnVisibility = useTableState(selectColumnVisibility(key));
  const setStateColumnVisibility = useTableState(selectSetColumnVisibility);

  // The reason to use useCallback here is to avoid render loops derived from the state changing
  const setColumnVisibility: OnChangeFn<VisibilityState> = useCallback(
    (visibilityUpdater) => {
      const newColumnVisibility =
        typeof visibilityUpdater === 'function'
          ? visibilityUpdater(columnVisibility)
          : visibilityUpdater;

      if (newColumnVisibility === columnVisibility) {
        return;
      }

      setStateColumnVisibility(key, newColumnVisibility);
    },
    [columnVisibility, key, setStateColumnVisibility],
  );

  return { columnVisibility, setColumnVisibility };
};
