import {
  API_BASE_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
} from '@/old/config/api_routes';
import { logout } from '@/old/state/actions/Login/login_actions';
import { store } from '@/old/state/store';
import {
  AccountApi,
  BillingApi,
  PatientApi,
  PtApi,
  VendorsApi,
} from '@/schema';
import { Configuration } from '@/schema/runtime';
import { USER_TIMEZONE } from '@/utils/date-formatters';
import {
  UNAUTHORIZED_STATUS_CODES,
  UnauthorizedError,
  inputIncludes,
} from '@/utils/fetch-utils';

const apiConfig = new Configuration({
  basePath: API_BASE_URL,
  headers: {
    'Django-Timezone': `${USER_TIMEZONE}`,
  },
  accessToken() {
    return store.getState().Session.token;
  },
  middleware: [
    {
      post: async ({ response, url }) => {
        // Logout user if unauthorized access, ignore if logout request
        if (
          response &&
          UNAUTHORIZED_STATUS_CODES.includes(response.status) &&
          inputIncludes(url, API_BASE_URL) &&
          !inputIncludes(url, API_LOGOUT_URL) &&
          !inputIncludes(url, API_LOGIN_URL)
        ) {
          await store.dispatch(logout(true));
          throw new UnauthorizedError();
        }
      },
    },
  ],
});

export const billingApi = new BillingApi(apiConfig);

export const providerMetadataApi = new PtApi(apiConfig);

export const accountApi = new AccountApi(apiConfig);

export const vendorsApi = new VendorsApi(apiConfig);

export const patientApi = new PatientApi(apiConfig);
