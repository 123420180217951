// #region Auth
export const EMAIL_VERIFY_URL = '/email-verify/:verifyId';
export const LOGIN_URL = '/login';
export const LOGOUT_URL = '/logout';
export const PASSWORD_RESET_URL = '/password-reset/:token';
export const REGISTRATION_URL = '/registration';
export const SSO_AUTH_URL = '/sso-auth';
// #endregion

// #region Bridge
export const BRIDGE_PREFIX = '/bridge/';

// Deprecated next 2
export const BRIDGE_APP_URL = '/bridge-app';
export const BRIDGE_SSO_URL = '/bridge-sso';

export const BRIDGE_SSO_TILE_URL = `${BRIDGE_PREFIX}sso-tile`;
export const BRIDGE_MAIN_APP_URL = `${BRIDGE_PREFIX}main-app`;

export const BRIDGE_PATIENT_DETAIL_TILE_URL = `${BRIDGE_PREFIX}patient-detail-tile`;
export const BRIDGE_PATIENT_DETAIL_URL = `${BRIDGE_PREFIX}patient-detail`;

export const BRIDGE_PATIENT_BILLING_TILE_URL = `${BRIDGE_PREFIX}patient-billing-tile`;
export const BRIDGE_PATIENT_BILLING_URL = `${BRIDGE_PREFIX}patient-billing`;
// #endregion

export const BILLING_URL = '/billing';
export const INTERACTION_LOG_URL = '/logs';
export const METRICS_URL = '/metrics';
export const PATIENT_LIST_URL = '/patient-list';
export const SUPPORT_URL = '/support';
