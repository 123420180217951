import { TooltipTrigger } from '@radix-ui/react-tooltip';
import { EllipsisVerticalIcon, LucideIcon } from 'lucide-react';
import { ForwardedRef, PropsWithChildren, forwardRef } from 'react';

import { SCREENS, useMediaQuery } from '@/hooks/use-media-queries';

import { EllipsisMenuTrigger } from './ellipsis-menu-trigger';
import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from './ui/dropdown-menu';
import { TooltipContent } from './ui/tooltip';

export interface ResponsiveActionsTriggerProps {
  Icon: LucideIcon;
  text: string;
  onClick?: () => void;
}

export const ResponsiveActionsTrigger = forwardRef<
  HTMLButtonElement | HTMLDivElement,
  ResponsiveActionsTriggerProps
>((props, ref) => {
  const { Icon, text, ...triggerProps } = props;
  // Delay required, DropdownMenuItem can't exist without a Menu
  const isMobile = useMediaQuery(SCREENS['max-sm'], 100);

  return isMobile ? (
    <DropdownMenuItem
      {...triggerProps}
      ref={ref as ForwardedRef<HTMLDivElement>}
      className="flex gap-2"
      onSelect={(e) => e.preventDefault()}
    >
      <Icon className="size-10" />
      {text}
    </DropdownMenuItem>
  ) : (
    <>
      <TooltipTrigger asChild>
        <Button
          {...triggerProps}
          ref={ref as ForwardedRef<HTMLButtonElement>}
          variant="ghost"
          size="icon"
          aria-label={text}
        >
          <Icon />
        </Button>
      </TooltipTrigger>

      <TooltipContent>{text}</TooltipContent>
    </>
  );
});
ResponsiveActionsTrigger.displayName = 'ResponsiveActionsTrigger';

/**
 * Actions wrapper, hover functionality is based on CSS classes,
 * for this to work, the hover target needs the class `group/actions-target`
 * which in this case is the relative div container.
 *
 * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state - TailwindCSS Documentation
 *
 * @param props React fragment with Ghost Icon Buttons with Tooltips
 * @returns
 */
export const PaginatedTableHoverActions = (props: PropsWithChildren) => {
  return (
    <div className="group/actions-table relative size-10">
      <div className="invisible absolute right-0 top-1/2 -mr-2 inline-flex -translate-y-1/2 gap-2 rounded-lg border bg-card p-2 text-card-foreground shadow-sm group-hover/actions-table:visible">
        {props.children}

        <Button
          variant="ghost"
          size="icon"
          className="visible"
          aria-label="Patient Hover Action"
        >
          <EllipsisVerticalIcon />
        </Button>
      </div>
    </div>
  );
};

export const PaginatedTableDropdownActions = (props: PropsWithChildren) => (
  <DropdownMenu>
    <EllipsisMenuTrigger />

    <DropdownMenuContent align="end">{props.children}</DropdownMenuContent>
  </DropdownMenu>
);

export const PaginatedTableResponsiveActions = (props: PropsWithChildren) => {
  const isMobile = useMediaQuery(SCREENS['max-sm']);

  return isMobile ? (
    <PaginatedTableDropdownActions>
      {props.children}
    </PaginatedTableDropdownActions>
  ) : (
    <PaginatedTableHoverActions>{props.children}</PaginatedTableHoverActions>
  );
};
