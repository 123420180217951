import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  authTokenSelector,
  loggedUserIdSelector,
} from '@/old/state/reducers/login_reducer';
import { providerMetadataApi } from '@/state/api-instances';
import { useMetadataState } from '@/state/metadata-state';

export const useMetadataSync = () => {
  const loggedUserId: number | null = useSelector(loggedUserIdSelector);
  const token: string | undefined = useSelector(authTokenSelector);

  const { setMetadata } = useMetadataState();

  const { data } = useQuery({
    queryKey: ['metadata'],
    queryFn: ({ signal }) =>
      providerMetadataApi.ptProvidersRetrieve(
        { id: `${loggedUserId}` },
        { signal },
      ),
    enabled: loggedUserId !== null && !isEmpty(token),
    meta: {
      errorMessage: 'Error fetching metadata',
    },
  });

  useEffect(() => {
    if (data) {
      setMetadata(data);
    } else {
      setMetadata();
    }
  }, [data, setMetadata]);
};
