/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ProviderPatientRelationship } from './ProviderPatientRelationship';
import {
  ProviderPatientRelationshipFromJSON,
  ProviderPatientRelationshipFromJSONTyped,
  ProviderPatientRelationshipToJSON,
} from './ProviderPatientRelationship';
import type { RTMCycle } from './RTMCycle';
import {
  RTMCycleFromJSON,
  RTMCycleFromJSONTyped,
  RTMCycleToJSON,
} from './RTMCycle';
import type { RTMEnrollment } from './RTMEnrollment';
import {
  RTMEnrollmentFromJSON,
  RTMEnrollmentFromJSONTyped,
  RTMEnrollmentToJSON,
} from './RTMEnrollment';
import type { UserSerializerSlim } from './UserSerializerSlim';
import {
  UserSerializerSlimFromJSON,
  UserSerializerSlimFromJSONTyped,
  UserSerializerSlimToJSON,
} from './UserSerializerSlim';

/**
 *
 * @export
 * @interface PatientSerializerSlim
 */
export interface PatientSerializerSlim {
  /**
   *
   * @type {UserSerializerSlim}
   * @memberof PatientSerializerSlim
   */
  readonly user: UserSerializerSlim;
  /**
   *
   * @type {Array<ProviderPatientRelationship>}
   * @memberof PatientSerializerSlim
   */
  readonly providerRelationships: Array<ProviderPatientRelationship>;
  /**
   *
   * @type {RTMCycle}
   * @memberof PatientSerializerSlim
   */
  readonly currentRtmcycle: RTMCycle | null;
  /**
   *
   * @type {RTMEnrollment}
   * @memberof PatientSerializerSlim
   */
  readonly latestRtmenrollment: RTMEnrollment | null;
}

/**
 * Check if a given object implements the PatientSerializerSlim interface.
 */
export function instanceOfPatientSerializerSlim(value: object): boolean {
  if (!('user' in value)) return false;
  if (!('providerRelationships' in value)) return false;
  if (!('currentRtmcycle' in value)) return false;
  if (!('latestRtmenrollment' in value)) return false;
  return true;
}

export function PatientSerializerSlimFromJSON(
  json: any,
): PatientSerializerSlim {
  return PatientSerializerSlimFromJSONTyped(json, false);
}

export function PatientSerializerSlimFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatientSerializerSlim {
  if (json == null) {
    return json;
  }
  return {
    user: UserSerializerSlimFromJSON(json['user']),
    providerRelationships: (json['provider_relationships'] as Array<any>).map(
      ProviderPatientRelationshipFromJSON,
    ),
    currentRtmcycle: RTMCycleFromJSON(json['current_rtmcycle']),
    latestRtmenrollment: RTMEnrollmentFromJSON(json['latest_rtmenrollment']),
  };
}

export function PatientSerializerSlimToJSON(
  value?: PatientSerializerSlim | null,
): any {
  if (value == null) {
    return value;
  }
  return {};
}
