import {
  type DefaultOptions,
  QueryCache,
  QueryClient,
} from '@tanstack/react-query';
import { toast } from 'sonner';

import { handleResponseError } from '@/models/ErrorPayload';
import { ResponseError } from '@/schema';

import {
  ResponseError as FetchResponseError,
  UnauthorizedError,
} from './fetch-utils';
import { reportToSentry } from './sentry';

const tanstackQueryRetryOptions: DefaultOptions['queries'] &
  DefaultOptions['mutations'] = {
  retry: (failCount: number, error: Error) => {
    if (error instanceof UnauthorizedError || failCount >= 3) {
      return false;
    }
    return true;
  },
  retryDelay: 1000,
  refetchOnWindowFocus: false,
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // Error handling for queries, should trigger on last retry failed
    onError: async (error, query) => {
      const isResponseError =
        error instanceof ResponseError || error instanceof FetchResponseError;
      const errorLabel = query.meta?.errorMessage ?? 'Request failed';

      let errorWrapper: Error;
      let context = {};

      if (isResponseError) {
        errorWrapper = new ResponseError(error.response, error.message);
        context = {
          uri: error.response.url,
          data: await error.response.clone().text(),
          status: error.response.status,
          headers: error.response.headers,
          statusText: error.response.statusText,
        };
      } else {
        errorWrapper = new Error(error.message);
        context = {
          error,
        };
      }

      errorWrapper.name = errorLabel;
      errorWrapper.stack = error.stack;

      if (!(error instanceof UnauthorizedError)) {
        reportToSentry(errorWrapper, context);
      }

      if (await handleResponseError(error, errorLabel)) {
        return;
      }

      toast.error(errorLabel, {
        description: 'Unidentified error, please contact support',
      });
    },
  }),
  defaultOptions: {
    queries: {
      ...tanstackQueryRetryOptions,
    },
    mutations: {
      ...tanstackQueryRetryOptions,
    },
  },
});
