/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
  PatientFromJSON,
  PatientFromJSONTyped,
  PatientToJSON,
} from './Patient';

/**
 *
 * @export
 * @interface EMRPatientRecord
 */
export interface EMRPatientRecord {
  /**
   *
   * @type {string}
   * @memberof EMRPatientRecord
   */
  readonly recordId: string | null;
  /**
   *
   * @type {any}
   * @memberof EMRPatientRecord
   */
  rawRecord?: any;
  /**
   *
   * @type {any}
   * @memberof EMRPatientRecord
   */
  authUser?: any;
  /**
   *
   * @type {any}
   * @memberof EMRPatientRecord
   */
  platform?: any;
  /**
   *
   * @type {string}
   * @memberof EMRPatientRecord
   */
  provider: string;
  /**
   *
   * @type {Date}
   * @memberof EMRPatientRecord
   */
  readonly dob: Date;
  /**
   *
   * @type {string}
   * @memberof EMRPatientRecord
   */
  readonly phone: string | null;
  /**
   *
   * @type {Patient}
   * @memberof EMRPatientRecord
   */
  readonly patient: Patient;
}

/**
 * Check if a given object implements the EMRPatientRecord interface.
 */
export function instanceOfEMRPatientRecord(value: object): boolean {
  if (!('recordId' in value)) return false;
  if (!('provider' in value)) return false;
  if (!('dob' in value)) return false;
  if (!('phone' in value)) return false;
  if (!('patient' in value)) return false;
  return true;
}

export function EMRPatientRecordFromJSON(json: any): EMRPatientRecord {
  return EMRPatientRecordFromJSONTyped(json, false);
}

export function EMRPatientRecordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EMRPatientRecord {
  if (json == null) {
    return json;
  }
  return {
    recordId: json['record_id'],
    rawRecord: json['raw_record'] == null ? undefined : json['raw_record'],
    authUser: json['auth_user'] == null ? undefined : json['auth_user'],
    platform: json['platform'] == null ? undefined : json['platform'],
    provider: json['provider'],
    dob: new Date(json['dob']),
    phone: json['phone'],
    patient: PatientFromJSON(json['patient']),
  };
}

export function EMRPatientRecordToJSON(value?: EMRPatientRecord | null): any {
  if (value == null) {
    return value;
  }
  return {
    raw_record: value['rawRecord'],
    auth_user: value['authUser'],
    platform: value['platform'],
    provider: value['provider'],
  };
}
