import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import {
  BillingBillinginstancesListRequest,
  BillingBillinginstancesListStatusEnum,
  BillingInstance,
  BillingInstanceBulkCancelCancellationReasonTypeEnum,
} from '@/schema';
import { BillingTab, BillingTabEnum } from '@/state/billing-state';
import { formatDateShort } from '@/utils/date-formatters';

import { PatientInfoFragment } from '../patient-tooltip';
import { DataTableColumnHeader, VisibilityColumnHeader } from '../table-header';
import { TooltipInitialsAvatar } from '../tooltip-avatars';
import { Checkbox } from '../ui/checkbox';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { BillingActionCell } from './billing-action-cell';
import { cancellationReasonLabelEnum } from './billing-cancel-action';
import { BillingCodeCell } from './billing-code-cell';
import { JustificationDialog } from './billing-justification-dialog';
import { BillingPendingReasonCell } from './billing-pending-reason-cell';

export type BillingSearchType = BillingBillinginstancesListRequest['search'];

export const BillingTabMapping: Record<
  BillingTabEnum,
  BillingBillinginstancesListRequest
> = {
  [BillingTab.Pending]: {
    status: BillingBillinginstancesListStatusEnum.Elg,
    currentlyBillable: false,
  },
  [BillingTab.Ready]: {
    status: BillingBillinginstancesListStatusEnum.Elg,
    currentlyBillable: true,
  },
  [BillingTab.Billed]: {
    status: BillingBillinginstancesListStatusEnum.Rec,
    ordering: '-reconciled',
  },
  [BillingTab.Cancelled]: {
    status: BillingBillinginstancesListStatusEnum.Cnc,
  },
};

// #region Column Definitions
const columnHelper = createColumnHelper<BillingInstance>();

export const checkboxColumn = columnHelper.display({
  id: 'select',
  size: 32,
  header: ({ table }) => (
    <Checkbox
      checked={
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && 'indeterminate')
      }
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  enableSorting: false,
  enableHiding: false,
});

export const patientColumn = columnHelper.accessor(
  (instance) => instance.patient,
  {
    id: 'patient__user__last_name',
    header: ({ table, column }) => (
      <DataTableColumnHeader table={table} title="Patient" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <Tooltip>
          <TooltipTrigger>{row.original.patient.user.fullName}</TooltipTrigger>

          <TooltipContent>
            <PatientInfoFragment patient={row.original.patient} />
          </TooltipContent>
        </Tooltip>
      );
    },
    enableHiding: false,
  },
);

const codeColumn = columnHelper.accessor((instance) => instance, {
  id: 'type',
  header: ({ table, column }) => (
    <DataTableColumnHeader table={table} title="Code" column={column} />
  ),
  cell: ({ getValue }) => (
    <BillingCodeCell
      codeType={getValue().type}
      unitsOf98981={getValue().unitsOf98981}
    />
  ),
  enableHiding: false,
  enableSorting: false,
});

const primaryProviderColumn = columnHelper.accessor(
  (instance) =>
    instance.patient.providerRelationships.find(
      (provider) => provider.isPrimary,
    )?.provider.user,
  {
    id: 'primaryProvider',
    header: 'Primary Provider',
    cell: ({ getValue }) => {
      const user = getValue();
      return user && <TooltipInitialsAvatar user={user} />;
    },
    meta: { label: 'Primary Provider' },
  },
);

const eligibleOnColumn = columnHelper.accessor(
  (instance) => formatDateShort(instance.billableOn),
  {
    id: 'billable_on',
    header: ({ table, column }) => (
      <DataTableColumnHeader
        table={table}
        title="Eligible on"
        column={column}
      />
    ),
    meta: { label: 'Eligible on' },
  },
);
const eligibleAsOfColumn = columnHelper.accessor(
  (instance) => formatDateShort(instance.billableOn),
  {
    id: 'billable_on',
    header: ({ table, column }) => (
      <DataTableColumnHeader
        table={table}
        title="Eligible as of"
        column={column}
      />
    ),
    meta: { label: 'Eligible as of' },
  },
);
const billedOnColumn = columnHelper.accessor(
  (instance) =>
    instance.reconciled ? formatDateShort(instance.reconciled) : '',
  {
    id: 'reconciled',
    header: ({ table, column }) => (
      <DataTableColumnHeader table={table} title="Billed on" column={column} />
    ),
    meta: { label: 'Billed on' },
  },
);
const cancelledOnColumn = columnHelper.accessor(
  (instance) =>
    instance.cancelledOn ? formatDateShort(instance.cancelledOn) : '',
  {
    id: 'cancelled_on',
    header: ({ table, column }) => (
      <DataTableColumnHeader
        table={table}
        title="Cancelled on"
        column={column}
      />
    ),
    meta: { label: 'Cancelled on' },
  },
);
export const pendingReasonColumn = columnHelper.display({
  id: 'pending',
  header: 'Pending Reason',
  cell: ({ row }) => <BillingPendingReasonCell row={row} />,
  enableSorting: false,
});
const cancellationReasonColumn = columnHelper.accessor(
  (instance) => {
    return instance.cancellationReasonType ===
      BillingInstanceBulkCancelCancellationReasonTypeEnum.Other
      ? `Other: ${instance.optionalCancellationReasonMessage}`
      : cancellationReasonLabelEnum[instance.cancellationReasonType!];
  },
  {
    id: 'cancellation_reason_type',
    header: ({ table, column }) => (
      <DataTableColumnHeader
        table={table}
        title="Cancellation Reason"
        column={column}
      />
    ),
    enableHiding: false,
    enableSorting: false,
  },
);

const justificationColumn = columnHelper.display({
  id: 'justification',
  header: 'Justification',
  cell: ({ row }) => <JustificationDialog row={row} />,
  enableSorting: false,
  enableHiding: false,
});

export const actionColumn = columnHelper.display({
  id: 'action',
  header: ({ table }) => <VisibilityColumnHeader table={table} />,
  cell: ({ table, row }) => <BillingActionCell table={table} row={row} />,
  enableSorting: false,
  enableHiding: false,
  size: 72,
});
// #endregion

// #region Table Column Definitions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pendingColumns: ColumnDef<BillingInstance, any>[] = [
  patientColumn,
  codeColumn,

  eligibleOnColumn,

  primaryProviderColumn,
  pendingReasonColumn,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const readyToBillColumns: ColumnDef<BillingInstance, any>[] = [
  checkboxColumn,
  patientColumn,
  codeColumn,

  eligibleAsOfColumn,

  primaryProviderColumn,
  justificationColumn,
  actionColumn,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const billedColumns: ColumnDef<BillingInstance, any>[] = [
  patientColumn,
  codeColumn,

  billedOnColumn,

  primaryProviderColumn,
  actionColumn,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cancelledColumns: ColumnDef<BillingInstance, any>[] = [
  patientColumn,
  codeColumn,

  cancelledOnColumn,

  primaryProviderColumn,

  cancellationReasonColumn,
  actionColumn,
];
// #endregion
