/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
  PatientFromJSON,
  PatientFromJSONTyped,
  PatientToJSON,
} from './Patient';

/**
 *
 * @export
 * @interface PaginatedPatientList
 */
export interface PaginatedPatientList {
  /**
   *
   * @type {number}
   * @memberof PaginatedPatientList
   */
  count: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedPatientList
   */
  next?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedPatientList
   */
  previous?: string;
  /**
   *
   * @type {Array<Patient>}
   * @memberof PaginatedPatientList
   */
  results: Array<Patient>;
}

/**
 * Check if a given object implements the PaginatedPatientList interface.
 */
export function instanceOfPaginatedPatientList(value: object): boolean {
  if (!('count' in value)) return false;
  if (!('results' in value)) return false;
  return true;
}

export function PaginatedPatientListFromJSON(json: any): PaginatedPatientList {
  return PaginatedPatientListFromJSONTyped(json, false);
}

export function PaginatedPatientListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedPatientList {
  if (json == null) {
    return json;
  }
  return {
    count: json['count'],
    next: json['next'] == null ? undefined : json['next'],
    previous: json['previous'] == null ? undefined : json['previous'],
    results: (json['results'] as Array<any>).map(PatientFromJSON),
  };
}

export function PaginatedPatientListToJSON(
  value?: PaginatedPatientList | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    count: value['count'],
    next: value['next'],
    previous: value['previous'],
    results: (value['results'] as Array<any>).map(PatientToJSON),
  };
}
