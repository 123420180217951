/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { DurationF28Enum } from './DurationF28Enum';
import {
  DurationF28EnumFromJSON,
  DurationF28EnumFromJSONTyped,
  DurationF28EnumToJSON,
} from './DurationF28Enum';
import type { ExtendDurationEnum } from './ExtendDurationEnum';
import {
  ExtendDurationEnumFromJSON,
  ExtendDurationEnumFromJSONTyped,
  ExtendDurationEnumToJSON,
} from './ExtendDurationEnum';
import type { RTMEnrollmentStatusEnum } from './RTMEnrollmentStatusEnum';
import {
  RTMEnrollmentStatusEnumFromJSON,
  RTMEnrollmentStatusEnumFromJSONTyped,
  RTMEnrollmentStatusEnumToJSON,
} from './RTMEnrollmentStatusEnum';
import type { UnenrolledReasonEnum } from './UnenrolledReasonEnum';
import {
  UnenrolledReasonEnumFromJSON,
  UnenrolledReasonEnumFromJSONTyped,
  UnenrolledReasonEnumToJSON,
} from './UnenrolledReasonEnum';
import type { WorkflowPatientEnrollment } from './WorkflowPatientEnrollment';
import {
  WorkflowPatientEnrollmentFromJSON,
  WorkflowPatientEnrollmentFromJSONTyped,
  WorkflowPatientEnrollmentToJSON,
} from './WorkflowPatientEnrollment';

/**
 *
 * @export
 * @interface RTMEnrollment
 */
export interface RTMEnrollment {
  /**
   *
   * @type {number}
   * @memberof RTMEnrollment
   */
  readonly id: number;
  /**
   *
   * @type {RTMEnrollmentStatusEnum}
   * @memberof RTMEnrollment
   */
  status: RTMEnrollmentStatusEnum | null;
  /**
   *
   * @type {WorkflowPatientEnrollment}
   * @memberof RTMEnrollment
   */
  readonly smsWorkflowEnrollment: WorkflowPatientEnrollment;
  /**
   *
   * @type {DurationF28Enum}
   * @memberof RTMEnrollment
   */
  duration: DurationF28Enum | null;
  /**
   *
   * @type {number}
   * @memberof RTMEnrollment
   */
  readonly enrolledBy: number | null;
  /**
   *
   * @type {Date}
   * @memberof RTMEnrollment
   */
  readonly unenrolledAt: Date | null;
  /**
   *
   * @type {number}
   * @memberof RTMEnrollment
   */
  readonly unenrolledBy: number | null;
  /**
   *
   * @type {UnenrolledReasonEnum}
   * @memberof RTMEnrollment
   */
  unenrolledReason: UnenrolledReasonEnum | null;
  /**
   *
   * @type {ExtendDurationEnum}
   * @memberof RTMEnrollment
   */
  extendDuration: ExtendDurationEnum;
  /**
   *
   * @type {string}
   * @memberof RTMEnrollment
   */
  workflow?: string;
  /**
   *
   * @type {Date}
   * @memberof RTMEnrollment
   */
  readonly created: Date | null;
}

/**
 * Check if a given object implements the RTMEnrollment interface.
 */
export function instanceOfRTMEnrollment(value: object): boolean {
  if (!('id' in value)) return false;
  if (!('status' in value)) return false;
  if (!('smsWorkflowEnrollment' in value)) return false;
  if (!('duration' in value)) return false;
  if (!('enrolledBy' in value)) return false;
  if (!('unenrolledAt' in value)) return false;
  if (!('unenrolledBy' in value)) return false;
  if (!('unenrolledReason' in value)) return false;
  if (!('extendDuration' in value)) return false;
  if (!('created' in value)) return false;
  return true;
}

export function RTMEnrollmentFromJSON(json: any): RTMEnrollment {
  return RTMEnrollmentFromJSONTyped(json, false);
}

export function RTMEnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RTMEnrollment {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    status: RTMEnrollmentStatusEnumFromJSON(json['status']),
    smsWorkflowEnrollment: WorkflowPatientEnrollmentFromJSON(
      json['sms_workflow_enrollment'],
    ),
    duration: DurationF28EnumFromJSON(json['duration']),
    enrolledBy: json['enrolled_by'],
    unenrolledAt:
      json['unenrolled_at'] == null ? null : new Date(json['unenrolled_at']),
    unenrolledBy: json['unenrolled_by'],
    unenrolledReason: UnenrolledReasonEnumFromJSON(json['unenrolled_reason']),
    extendDuration: ExtendDurationEnumFromJSON(json['extend_duration']),
    workflow: json['workflow'] == null ? undefined : json['workflow'],
    created: json['created'] == null ? null : new Date(json['created']),
  };
}

export function RTMEnrollmentToJSON(value?: RTMEnrollment | null): any {
  if (value == null) {
    return value;
  }
  return {
    status: RTMEnrollmentStatusEnumToJSON(value['status']),
    duration: DurationF28EnumToJSON(value['duration']),
    unenrolled_reason: UnenrolledReasonEnumToJSON(value['unenrolledReason']),
    extend_duration: ExtendDurationEnumToJSON(value['extendDuration']),
    workflow: value['workflow'],
  };
}
