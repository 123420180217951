import {
  ExtendDurationEnum,
  type Patient,
  type PatientSerializerSlim,
} from '@/schema';
import { formatDateShort } from '@/utils/date-formatters';

interface PatientInfoFragmentProps {
  patient: Patient | PatientSerializerSlim;
}

export const PatientInfoFragment = (props: PatientInfoFragmentProps) => {
  const { patient } = props;

  return (
    <>
      <p>Phone: {patient.user.phone}</p>

      <p>
        Current Flow:{' '}
        {patient.latestRtmenrollment?.smsWorkflowEnrollment?.workflow?.name ??
          'None'}
      </p>

      <p>
        Birth Date:{' '}
        {patient.user.birthDate
          ? formatDateShort(patient.user.birthDate)
          : undefined}
      </p>

      <p>
        Current Cycle:{' '}
        {patient.currentRtmcycle ? (
          <>
            {patient.currentRtmcycle.cycleNumber} of{' '}
            {(patient.latestRtmenrollment?.duration ??
              ExtendDurationEnum.NUMBER_30) / 30}
          </>
        ) : (
          'Not currently enrolled.'
        )}
      </p>
    </>
  );
};
