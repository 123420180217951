import { Suspense } from 'react';
import { Outlet } from 'react-router';

import { Skeleton } from '../ui/skeleton';

export const AuthLayout = () => (
  <div className="flex h-dvh w-full flex-col overflow-y-auto p-4">
    <div className="flex flex-1 flex-col items-center justify-center gap-4">
      <Suspense fallback={<Skeleton className="size-96" />}>
        <Outlet />
      </Suspense>
    </div>
  </div>
);
